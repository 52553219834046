<template>
  <div class="gallery-large" v-bind="selected">

    <video autoplay controls loop muted v-if="selected.videoSrc">
      <source :src="selected.videoSrc" type="video/mp4" />
    </video>
    <img v-if="selected.imgSrc" :src="selected.imgSrc" :alt="this.$t(selected.description)" />

    <button class="arrow arrow-left" @click="prevImage()">&lt;</button>
    <button class="arrow arrow-right" @click="nextImage()">&gt;</button>
  </div>
  
  <div class="gallery-bar">
    <div class="preview" v-for="(el,index) of elements" v-bind:key="index">
      <button @click="selectImage(index)">
        <img :src="el.thumbSrc ? el.thumbSrc : el.imgSrc" :alt="this.$t(el.description)"  />
      </button>
      <p class="description">{{ $t(el.description) }}</p>
    </div>

  </div>

</template>


<script>
  export default {
    name: 'GallerySimple',
    data() {
      return {
        selected: this.elements[0],
        index: 0
      }
    },
    methods: {
      selectImage(index) {
        this.index = index;
        this.selected = this.elements[index];
      },
      nextImage() {
        this.index++;
        if (this.index >= this.elements.length) this.index = 0;
        this.selectImage(this.index);

      },
      prevImage() {
        this.index--;
        if (this.index < 0) this.index = this.elements.length - 1;
        this.selectImage(this.index);

      }
    },
    props: ['elements'],
    computed: {
      //selected () {return this.props.elements[this.index];}
      //selected: {imageSrc:'', description: '', videoSrc: null}
    },
    setup() {
      //console.log(props);
      //data.selected = props.elements[data.index];
    },
    mounted() {
    }
  };
</script>



<style scoped>

.gallery-large {
  padding: 10px 0px;
  position: relative;
}


.gallery-large video {
    display: block;
    margin: auto;
    
    width: 100%;
    max-width: 700px;
  }

  
.gallery-large img {
    display: block;
    margin: auto;
    
    width: 100%;
  }

  
.gallery-large .arrow {
    position: absolute;
    display: block;
    top: 50%;
    height: 100px;
    width: 100px;
    font-size: 64pt;
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
    color: rgba(0, 0, 0, 0.2);
    margin: -50px 0px 0px 0px;
    padding: 0px;
    transition: color .5s;
  }
  
.gallery-large .arrow:hover {
    color: rgba(0, 0, 0, 0.8);
  }

  
.gallery-large .arrow-left {
    left: -90px;
    
  }

  
.gallery-large .arrow-right {
    right: -90px;
  }
  



.gallery-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 100%;
  margin: 0px;
}
  

.gallery-bar .preview {
    margin: 2px;
    min-width: 10%;
    max-width: 24%;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

.gallery-bar .preview button {
      border: none;
      background-color: inherit;
      cursor: pointer;
      margin: 0px;
      padding: 0px;
}
.gallery-bar .preview button img {
      width: 100%;
      height: auto;
    }


    .gallery-bar .description {
        font-size: 8pt;
        margin-top: -5px;
      }
    
    
@media screen and (max-width: 800px) {
  .gallery-large video {
    max-width: 100%;
  }

  .preview {
    .description {
      display: none;
    }
  }

  .arrow {
    color: rgba(255,255,255,.8) !important;
    font-size: 32pt !important;
  }

  .arrow-left {
    left: -20px !important;
  }

  .arrow-right {
    right: -20px !important;
  }
}

</style>
