<template>
  <footer>
    <div class="container footer-parent">
      <div class="row">
        <div class="col s12 m6">
          <router-link to="/"><img src="@/assets/BRAB_logo_transparent.png" class="logo" /></router-link>
        </div>
        <div class="info col s12 m6">
          <h3>Begagnade Rymdskepp AB</h3>
          <p>c/o Marcus Grennborg</p>
          <p>Solventilsgatan 1, 417 23 Göteborg</p>
          <p>info@begagnaderymdskepp.se</p>
        </div>
        <div class="col s12 m12 copyright">
          <p>&copy; 2024 Begagnade Rymdskepp AB</p>
        </div>
        </div>
      </div>
    </footer>
  
</template>

<script>
  export default {
    name: 'MainFooter'
  };
</script>



<style scoped>
footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
}

.footer-parent {
  display: flex;
  flex-wrap: wrap;

  align-content: end;
}

.logo {
  
  width: 100%;
  max-width: 300px;
  
}

.info {
  text-align: left;
  h3 {
    font-size: 20px;
  }
}

.info p {
  margin: 0px;
}

.copyright {
  margin-top: 20px;
  flex-grow: 10;
  width: 100%;
  color: #777;
}
</style>
