<template>
  <header class="container">
      <div class="logo">
        <router-link :to="customLogo == null ? '/' : customLogo.to">
          <img v-if="customLogo == null" src="@/assets/rocket.png" alt="Begagnade Rymdskepp" />
          <img v-if="customLogo != null" :src="customLogo.src" :alt="customLogo.alt" />
        </router-link>
      </div>
      <nav v-if="links != null">
        <ul>
            <li v-for="link of links" v-bind:key="link"><router-link v-if="link.to" :to="link.to">{{ link.text }}</router-link><a v-if="link.href" :href="link.href" @click="navigationClicked">{{ $t(link.text) }}</a></li>
        </ul>
      </nav>

      <div class="language-selector">
        <span class="language" v-for="language of languages" v-bind:key="language">
          <button :class="$i18n.locale == language.code ? 'flag selected' : 'flag'" @click="changeLanguage(language.code)">
            <img v-if="language.code == 'sv'" src="@/assets/se.png" alt="Svenska">
            <img v-if="language.code == 'en'" src="@/assets/en.png" alt="English">
            <!--<img v-if="language.code == 'en'" src="@/assets/um.svg" alt="English">-->
          </button>
        </span>
      </div>
  </header>
  <div id="menu-placeholder"></div>
  
</template>

<script>
  export default {
    name: 'MainHeader',
    props: ['links', 'customLogo'],
    methods: {
      changeLanguage (code) {
        //this.$router.push({language: code, to: '/:language'});
        //this.$router.push(`/${code}`);
        this.$i18n.locale = code;
      },
      navigationClicked(e) {
        //window.scrollBy(0, -20);
        //e.preventDefault();

        // Prevent the default behavior of the anchor link
        e.preventDefault();

        // Get the target element's ID from the href attribute
        var targetId = e.target.getAttribute('href').substring(1);

        // Get the target element
        var targetElement = targetId == '' ? document.body : document.getElementById(targetId);

        var offset = document.getElementById("menu-placeholder").offsetHeight;

        // Calculate the target position, considering the offset
        var targetPosition = targetElement.offsetTop - offset;

        // Scroll to the target position
        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth' // Enable smooth
        });
      }
    },
    data () {
      return {
        languages: [
          {code: 'en', text: 'English'},
          {code: 'sv', text: 'Svenska'}
        ]
      }
    }
  };
</script>



<style scoped>
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: #fff;
    opacity: .95;
    z-index: 10;

}

#menu-placeholder {
  height: 75px;
}

.logo img {
    width: 50px; /* Make the logo responsive */
    height: auto;
    margin: 10px 0px 0px 0px;
}

nav {
    margin-top: 10px; /* Add some spacing between logo and navigation on small screens */
    flex-grow: 1;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: right; 
    flex-wrap: nowrap; /* Allow navigation items to wrap on small screens */
}

nav ul li a {

    margin-bottom: 10px; /* Add margin-bottom to create space between navigation items */
    text-overflow: clip;
}

nav a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    text-overflow: clip;
    /*height: 56px;
    overflow: hidden;*/
}

.language-selector {
  margin-top: 12px;
  margin-left: 10px;
  display: flex;  
}

.language {
}
.flag {
  cursor: pointer;
  border: none;
  padding: 0px;
  margin-left: 5px;
  width: 25px;
  background: none;

}

.flag   img {
    width: 25px;
}

.flag.selected {
    display: none;
  }


/* Media query for small screens */
@media only screen and (max-width: 768px) {
    header {
        flex-direction: column; /* Stack logo and navigation vertically on small screens */
        text-align: center;
    }

    nav {
        margin-top: 0; /* Remove top margin on small screens */
    }

    nav ul {
        justify-content: center; /* Center navigation items on small screens */
    }

    nav ul li {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .language-selector {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    #menu-placeholder {
      height: 120px;
    }
}
</style>