<script language="ts">
import Constants from '@/Constants';
import superagent from 'superagent';

export default ({
  async created () {
  },
  components: {
  },

  data() {
    return {
      name: '',
      email: '',
      comment: '',
      errorOnValue : null,
      successfulSubmit: false,
    }
  },
  methods: {
    async formSubmit(e) {
      e.preventDefault();

      try {
        let formSubmitResult = await superagent.post(`${Constants.apiUrl}/contact`).send({
          name: this.name, 
          email: this.email, 
          comment: this.comment
        });

        console.log(formSubmitResult.body);

        if (formSubmitResult.body.created) {
          this.successfulSubmit = true;
          this.errorOnValue = '';
        } else {
          this.successfulSubmit = false;
          this.errorOnValue = formSubmitResult.body.validationFailed;
          console.log(formSubmitResult.body.validationFailed);
        }


      } catch (e) {
        console.error(e);
        this.successfulSubmit = false;
      }

      

    }
  }
})

</script>

<template>

    <div class="infotext">
      <div class="contact-form">
        <div class="form" v-if="!successfulSubmit">
          <form @submit="formSubmit">
            <label for="name">{{ $t("contact_form.name") }}</label>
            <div class="validation-error" v-if="errorOnValue === 'name'">{{ $t("contact_form.name-validation-error") }}</div>
            <input class="text-input" type="text" v-model="name" name="name" />

            <label for="email">{{ $t('contact_form.email') }}</label>
            <div class="validation-error" v-if="errorOnValue === 'email'">{{ $t('contact_form.email-validation-error') }}</div>
            <input class="text-input" type="text" v-model="email" name="email" />

            <label for="comment">{{ $t('contact_form.message') }}</label>
            <div class="validation-error" v-if="errorOnValue === 'comment'">{{ $t('contact_form.message-validation-error') }}</div>
            <textarea name="comment" v-model="comment"></textarea>

            <input type="submit" class="btn-large" :value="$t('contact_form.send')" />
          </form>
        </div>
        <div class="success-message" v-if="successfulSubmit">
          <p class="icon">📧</p>
          <p>{{ $t('contact_form.message-sent') }}</p>
        </div>
      </div>
      
    </div>
    
    

</template>

<style scoped>

  label {
    display: block;
    width: 100%;
  }

  .text-input {
    display: block;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }

  textarea {
    display: block;
    width: 100%;
    height: 109px;
    margin-bottom: 10px;
  }

  .btn-large {

    float:right;

  }

  .success-message .icon {
    font-size: 72pt;
    margin: 0px;
  }


  .contact-form {
    flex-grow: 2;
  }

  .validation-error {
    color: rgb(133, 12, 12);
    font-style: italic;
    padding: 10px;
  }

  .infotext {
    display: flex;
    align-items: stretch;
  }

  .portfolio {
    padding-left: 10px;
    flex-shrink: 3;
  }

  .portfolio-test {
    width: 30%;
    float: right;
    padding-left: 10px;
  }

  @media screen and (max-width: 800px) {
    .portfolio {
      display: none;
    }
  }

</style>