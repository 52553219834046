<script >
import GallerySimple from '@/components/GallerySimple.vue';
import MainHeader from '@/components/MainHeader.vue';

  export default {
  components: { GallerySimple, MainHeader },
  data() {
    return {
      galleryElements: [
        {
          videoSrc: process.env.BASE_URL+'hue-mr/hue-usecase.mp4',
          thumbSrc: process.env.BASE_URL+'hue-mr/video-thumb.png',
          description: ('hue_mr_app.images.video'),
        },
        /*{
          imgSrc: process.env.BASE_URL+'hue-mr/screenshot-bridge.png',
          description: 'Setting up on the Hue Bridge',
        },*/
        {
          imgSrc: process.env.BASE_URL+'hue-mr/screenshot-light.png',
          description: ('hue_mr_app.images.overview_lit'),
        },
        {
          imgSrc: process.env.BASE_URL+'hue-mr/screenshot-dark.png',
          description: ('hue_mr_app.images.overview_dark'),
        },
        /*{
          imgSrc: process.env.BASE_URL+'hue-mr/screenshot-pinned-light.png',
          description: 'Pin lights next to a physical light source',
        },*/
        {
          imgSrc: process.env.BASE_URL+'hue-mr/screenshot-menu.png',
          description: ('hue_mr_app.images.menu'),
        }
      ],
      links: [
        { href: '#', text: 'hue_mr_app.menu.home'},
        { href: '#usps', text: 'hue_mr_app.menu.about'},
        { href: '#gallery', text: 'hue_mr_app.menu.gallery'},
        { href: '#download', text: 'hue_mr_app.menu.download'},
        //{ href: 'https://oculus.com', text: 'Download now!'},
      ],
      usps: [
        {
          img: process.env.BASE_URL+'hue-mr/screenshot-bridge.png',
          header: 'hue_mr_app.usps[0].header',
          text: 'hue_mr_app.usps[0].description'
        },
        {
          img: process.env.BASE_URL+'hue-mr/screenshot-pinned-light.png',
          header: 'hue_mr_app.usps[1].header',
          text: 'hue_mr_app.usps[1].description'
        },
        {
          img: process.env.BASE_URL+'hue-mr/screenshot-menu.png',
          header: 'hue_mr_app.usps[2].header',
          text: 'hue_mr_app.usps[2].description'
        }
      ]
    }
  }
  };
</script>

<template>
  <main-header :links="links"></main-header>
  <!--<main-header :links="links" :customLogo="{src: '/hue-mr/bulb-transparent.png', alt:'Home', to:'/apps/hue'}"></main-header>-->
  <main>
    <img src="@/assets/hue-mr/hero.png" class="hero"/>
    <h1>{{$t('hue_mr_app.header')}}</h1>
    <p>{{$t('hue_mr_app.description')}}</p>

    <!--
    <ul id="usps" class="usps">
      <li><b>Easy to set up</b>: start the app, press the Hue bridge button. That's it. </li>
      <li>Place <b>virtual light bulbs</b> by your physical lights. Use them to control your lights, when you are far away or when you are close. </li>
      <li>Control your  lights from a <b>simple dashboard</b>, with all your lights grouped by rooms.  </li>
      <li>Full support for <b>hand tracking</b>, no need to use your controllers. </li>
      <li><b>Proximity mode</b> (or sensor mode) automatically turns the lights on and off as you move around in your home. </li>
    </ul>
    -->

    <section id="usps">

      <!--
      <div class="usp row">
        <div class="usp-content col m6 s12 valign-wrapper ">
          <div class="">
            <h3>Feel like a wizard</h3>
            <p>Start the app, press the Hue bridge button. That's it. </p>
          </div>
        </div>
        <div class="col m6 s12">
            <img src="@/assets/hue-mr/banner square.png" alt="Hue MR" />
        </div>
      </div>
      -->

      <div :class="'usp' + (index % 2==0 ? '' : ' reversed')" v-for="(usp, index) of usps" v-bind:key="usp">
        <div class="usp-content">
          <div class="">
            <h3>{{$t(usp.header)}}</h3>
            <p>{{$t(usp.text)}}</p>
          </div>
        </div>
        <div class="image">
          <img :src="usp.img" :alt="this.$t(usp.text)" />
        </div>
      </div>

      <!--<div class="usp row" v-for="(usp) of usps" v-bind:key="usp">
        <div class="usp-content col m6 s12 valign-wrapper ">
          <div class="">
            <h3>{{usp.header}}</h3>
            <p>{{usp.text}}</p>
          </div>
        </div>
        <div class="col m6 s12">
          <img :src="usp.img" :alt="usp.text" />
        </div>
      </div>-->

      <!--<div class="usp row">
        <div class="usp-content col m6 s12 valign-wrapper ">
          <div class="">
            <h3>Easy to set up</h3>
            <p>Start the app, press the Hue bridge button. That's it. </p>
          </div>
        </div>
        <div class="col m6 s12">
            <img src="@/assets/hue-mr/screenshot-bridge.png" alt="Hue MR" />
        </div>
      </div>

      <div class="usp row">
        <div class="col m6 s12">
            <img src="@/assets/hue-mr/screenshot-pinned-light.png" alt="Hue MR" />
        </div>
        <div class="usp-content col m6 s12 valign-wrapper ">
          <div class="">
            <h3>Virtual light bulbs</h3>
            <p>Place virtual light bulbs by your physical lights. Use them to control your lights, when you are far away or when you are close.  </p>
          </div>
        </div>
      </div>

      <div class="usp row">
        <div class="usp-content col m6 s12 valign-wrapper ">
          <div class="">
            <h3>Dashboard</h3>
            <p>Control your  lights from a simple dashboard, with all your lights grouped by rooms. </p>
          </div>
        </div>
        <div class="col m6 s12">
            <img src="@/assets/hue-mr/screenshot-menu.png" alt="Hue MR" />
        </div>-->
      

      

      <h3>{{ $t("hue_mr_app.misc_usps") }}</h3>
      <ul class="usps">
        <li v-html="$t('hue_mr_app.misc_usp_list[0]')"></li>
        <li v-html="$t('hue_mr_app.misc_usp_list[1]')"></li>
        <li v-html="$t('hue_mr_app.misc_usp_list[2]')"></li>
      </ul>
    </section>




    <section id="gallery">
      <h2>{{ $t('hue_mr_app.gallery')}}</h2>
      <gallery-simple :elements="galleryElements"></gallery-simple>
    </section>

    <section id="download">
      <h2>{{ $t('hue_mr_app.download')}}</h2>
      <div class="">

        <a class="btn-large" href="https://www.meta.com/sv-se/experiences/6880462102065761/">{{ $t('hue_mr_app.download-button')}}</a>
      </div>
      
      <!--
      <div class="usp row">
        <div class="usp-content col m6 s12 valign-wrapper ">
          <div class="">
            <h2>How to get it</h2>
            <button class="btn-large center-align" disabled>Download from Quest App Lab *</button>
            <p>* The app will soon be available on Meta Quest App Lab. </p>
          </div>
        </div>
        <div class="col m6 s12">
            <img src="@/assets/hue-mr/banner square.png" alt="Hue MR" />
        </div>
      </div>
      -->

      
    </section>
    


    <h3 id="disclaimer">{{ $t('hue_mr_app.disclaimer')}}</h3>
    <p>{{ $t('hue_mr_app.disclaimer-text')}}</p>

    <h3 id="privacy-policy">{{ $t('hue_mr_app.privacy-policy')}}</h3>
    <p>{{ $t('hue_mr_app.privacy-policy-text')}}</p>

  </main>
</template>


<style scoped>

p {
  padding-bottom: 10px;
}
.hero {
  width: 100%;
}



  #usps{
    margin-top: 100px;
  }

  .usp {
    padding-top: 30px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;
  }
  .usp .usp-content {
    width: 45%;

    
  
  }

  .usp .image {
    width: 45%;
  }

  .usp img {

    width: 100%;

  }
  

  .usp + .reversed {
    flex-direction: row-reverse;
  }

  @media only screen and (max-width: 768px) {
    .usp {
      flex-direction: column !important;

      .usp-content {
        width: 100%;
      }

      .image {
        width: 75%;
        margin: auto;
      }
    }
  }

 




ul.usps {
  margin: 10px 0px 30px 0px;
  padding: 10px 30px;
  background-color: #f7ebe5;
  border-radius: 5px;
  font-family: sans-serif;
  b {
    font-weight: bold;
  }
  li {
    padding: 2px;
    padding-bottom: 4px;
    list-style-type: disc;
  }
}
</style>
